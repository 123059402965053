@import "./imports";

html, body, #root, .App {
  width: 100%;
  display: block;
}

body {
  margin: 0;
  font-family: proximanova,  -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --nav-height: 64px
}

#osunavbar {
  position: relative;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

#root {
  background-color: var(--root-background-color);
  color: var(--root-text-color);
}

#osunavbar {
  position: relative;
}

table {
  table-layout: fixed;
}

html, body, #root {
  height: 100%;
}

.App {
  height: auto;
  background-color: var(--root-background-color);
}


main, h1, h2 {
  &:focus {
     outline: none
  }
}
